<template>
  <div class="ocr-pages">
    <div class="ocr-title">
      What do you want to OCR?
    </div>
    <div class="ocr-selection">
      <div class="ocr-selection-card" :class="{ 'highlighted-card' : !ocrAllPages }" @click="updateOcrValue(false)">
        <VasionFileOutlined class="fill-grey" :class="{ 'fill' : !ocrAllPages}" />
        <h5>1st Page</h5>
      </div>
      <div class="ocr-selection-card" :class="{ 'highlighted-card' : ocrAllPages }" @click="updateOcrValue(true)">
        <VasionFilesOutlined class="fill-grey" :class="{ 'fill' : ocrAllPages}" />
        <h5>All Pages</h5>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DocumentOcrPages',
  data() {
    return {
      ocrAllPages: false,
    }
  },
  methods: {
    updateOcrValue(value) {
      this.ocrAllPages = value
      this.$emit('ocrAllPagesUpdated', this.ocrAllPages)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.ocr-pages {
    width: 700px;
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ocr-pages > div {
    margin: 10px 0px;
  }
  .ocr-title {
    font-weight: bold;
  }
  .ocr-selection {
    display: flex;
    justify-content: center;

  }
  .ocr-selection-card {
    width: 190px;
    height: 145px;
    margin: 0 16px 0 0;
    padding: 24px 63px;
    border-radius: 8px;
    cursor: pointer;
    border: solid 2px $grey-100;
  }
  .highlighted-card {
    border: 2px solid $secondary;
    .card-footer {
      background-color: $secondary;
      border-top: 2px solid $secondary;
    }
  }
  .fill-grey {
    fill: $grey-100;
  }
  .fill {
    fill: $secondary;
  }
</style>
