<template>
  <div class="mainDiv">
    <div class="row">
      <VasionDropList
        v-slot="slotItem"
        v-model="attributeForm"
        class="fullWidth"
        :dataArray="attributeFormList"
        width="'100%'"
        :title="`${$formsLabel.toUpperCase()}`"
        placeholder="..."
        type="plain-list"
        displayName="name"
        valueName="value"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div class="row">
      <VasionCheckbox
        id="use-barcode-splitter"
        name="use-barcode-splitter"
        class="row-checkbox"
        :checked="useBarcodeSplitter"
        :isDisabled="useBarcodeSplitterDisabled"
        @change="useBarcodeSplitter= !useBarcodeSplitter"
      >
        Use Barcode Splitter
      </VasionCheckbox>
      <VasionCheckbox
        id="read-barcode"
        name="read-barcode"
        class="row-checkbox"
        :isDisabled="readBarcodeDisabled"
        :checked="readBarcode"
        @change="readBarcode= !readBarcode"
      >
        Read Barcode
      </VasionCheckbox>
    </div>
    <div class="row">
      <div class="row-checkbox">
        <VasionCheckbox
          id="split-on-page-count"
          name="split-on-page-count"
          style="margin-right: 50px"
          :checked="splitOnPageCount"
          @change="splitOnPageCount= !splitOnPageCount"
        >
          Split on Page Count
        </VasionCheckbox>
        <VasionInput
          id="splitOnPageCountNumber"
          v-model="splitOnPageCountNumber"
          class="row-input"
          inputType="top-white"
          name="splitOnPageCountNumber"
          specialType="number"
          style="padding-right: 13px"
          :isDisabled="!splitOnPageCount"
          min="0"
          :width="'100'"
        />
      </div>
      <VasionCheckbox
        id="read-first-page-only"
        name="read-first-page-only"
        class="row-checkbox"
        :checked="readFirstPageOnly"
        :isDisabled="readFirstPageOnlyDisabled"
        @change="readFirstPageOnly= !readFirstPageOnly"
      >
        Read First Page Only
      </VasionCheckbox>
    </div>
    <div class="row">
      <VasionCheckbox
        id="delete-original-documents"
        name="delete-original-documents"
        class="row-checkbox"
        :checked="deleteOriginalDocuments"
        :isDisabled="deleteOriginalDocumentsDisabled"
        @change="deleteOriginalDocuments= !deleteOriginalDocuments"
      >
        Delete Original Documents
      </VasionCheckbox>
      <VasionCheckbox
        id="create-new-document"
        name="create-new-document"
        class="row-checkbox"
        :checked="createNewDocument"
        :isDisabled="createNewDocumentDisabled"
        @change="createNewDocument= !createNewDocument"
      >
        Create New Document
      </VasionCheckbox>
    </div>
    <div class="row">
      <VasionCheckbox
        id="keep-barcode-page"
        name="keep-barcode-page"
        class="row-checkbox"
        :checked="keepBarcodePage"
        @change="keepBarcodePage= !keepBarcodePage"
      >
        Keep Barcode Page
      </VasionCheckbox>
      <VasionCheckbox
        id="use-barcode-as-value-name"
        name="use-barcode-as-value-name"
        class="row-checkbox"
        :checked="useBarcodeValueAsName"
        @change="useBarcodeValueAsName= !useBarcodeValueAsName"
      >
        Use Barcode as Value Name
      </VasionCheckbox>
    </div>
    <div class="row">
      <VasionCheckbox
        id="barcode-on-last-page"
        name="barcode-on-last-page"
        class="row-checkbox"
        :checked="barcodeOnLastPage"
        :isDisabled="barcodeOnLastPageDisabled"
        @change="barcodeOnLastPage = !barcodeOnLastPage"
      >
        Barcode on Last Page
      </VasionCheckbox>
    </div>
    <div class="row">
      <VasionDropList
        v-slot="slotItem"
        v-model="barcodeIndex"
        class="fullWidth"
        :dataArray="filteredBarcodeList"
        width="'100%'"
        title="Barcode Index"
        placeholder="Select Index..."
        type="plain-list"
        displayName="name"
        valueName="value"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div class="row">
      <VasionInput
        id="prefix"
        v-model="prefix"
        class="fullWidth"
        inputType="top-white"
        name="prefix"
        title="Prefix"
        width="100%"
        placeholder="Enter Prefix..."
      />
    </div>
    <div class="row">
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedSplitterOption"
        class="fullWidth"
        :dataArray="splitterOptions"
        width="100%"
        title="Splitter Option"
        placeholder="Select Option..."
        type="plain-list"
        displayName="name"
        valueName="value"
        :openAbove="true"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div class="row">
      <div class="column fullWidth">
        <div v-for="(value, index) of splitterValues" :key="index" class="fullWidth">
          <div class="splitter-values">
            <VasionInput
              id="value-from-input"
              v-model="value.message"
              class="fullWidth"
              inputType="top-white"
              name="value-from-input"
              width="100%"
              placeholder="Enter Value..."
              :isDisabled="splitterValuesDisabled"
              :title="index === 0 ? 'Value(s)' : ''"
            />
            <div
              v-if="index !== 0"
              class="minus-button"
              :class="{ 'plus-and-minus-disabled': splitterValuesDisabled }"
              @click="removeValue(index)"
            >
              <VasionRemoveIcon />
            </div>
            <div
              v-if="index === 0"
              class="plus-button"
              :class="{ 'plus-and-minus-disabled': splitterValuesDisabled }"
              @click="pushValue()"
            >
              <VasionAddIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <VasionInput
        id="value-from-input"
        v-model="barcodeRangeFrom"
        class="halfWidth"
        inputType="top-white"
        name="value-from-input"
        title="Value From"
        width="100%"
        specialType="number"
        min="0"
        :isDisabled="barcodeRangeFromAndToDisabled"
        placeholder="Enter Value..."
      />
      <VasionInput
        id="value-to-input"
        v-model="barcodeRangeTo"
        class="halfWidth"
        inputType="top-white"
        name="value-to-input"
        title="Value To"
        width="100%"
        min="0"
        specialType="number"
        :isDisabled="barcodeRangeFromAndToDisabled"
        placeholder="Enter Value..."
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DocumentBarcodeRecognition',
  data() {
    return {
      attributeForm: '',
      barcodeIndex: { value: null },
      barcodeList: [],
      barcodeRangeFrom: '',
      barcodeRangeTo: '',
      barcodeOnLastPage: false,
      createNewDocument: false,
      deleteOriginalDocuments: false,
      keepBarcodePage: false,
      prefix: '',
      readBarcode: false,
      readFirstPageOnly: false,
      selectedSplitterOption: '',
      splitterOptions: [
        { name: 'Range Value', value: 0 },
        { name: 'Start With', value: 1 },
        { name: 'Values', value: 2 },
        { name: 'Advance Barcode', value: 3 },
        { name: 'Value Changed', value: 4 },
      ],
      splitterValues: [{ message: '' }],
      splitOnPageCount: false,
      splitOnPageCountNumber: 0,
      useBarcodeSplitter: false,
      useBarcodeValueAsName: false,
    }
  },
  computed: {
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    barcodeRangeFromAndToDisabled() { return this.selectedSplitterOption?.name !== 'Range Value' },
    barcodeOnLastPageDisabled() { return !this.useBarcodeSplitter },
    createNewDocumentDisabled() { return this.useBarcodeSplitter || this.readBarcode || this.readFirstPageOnly || this.splitOnPageCount },
    deleteOriginalDocumentsDisabled() { return this.splitOnPageCount },
    filteredBarcodeList() {
      const selectedBarcode = this.barcodeList.filter(barcode => barcode.IndexFormID === this.attributeForm?.value)
      if (!selectedBarcode) {
        return [{}]
      }
      return selectedBarcode.map(barcode => { return { name: barcode.ConfigName, value: barcode.ID } })
    },
    readBarcodeDisabled() { return this.useBarcodeSplitter },
    readFirstPageOnlyDisabled() { return this.useBarcodeSplitter },
    splitterValuesDisabled() { return this.selectedSplitterOption?.name !== 'Start With' && this.selectedSplitterOption?.name !== 'Values' },
    useBarcodeSplitterDisabled() { return this.splitOnPageCount || this.readBarcode || this.readFirstPageOnly },
  },
  async created() {
    [, this.barcodeList] = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('capture/getAllBarcodeIndex'),
    ])
  },
  methods: {
    buildPayload() {
      const payload = {
        objectID: this.attributeForm.value,
        useBarcodeSplitter: this.useBarcodeSplitter,
        readBarcode: this.readBarcode,
        splitOnPageCount: this.splitOnPageCountNumber,
        readFirstPageOnly: this.readFirstPageOnly,
        deleteOriginalDocument: this.deleteOriginalDocuments,
        createNewDocument: this.createNewDocument,
        keepBarcodePage: this.keepBarcodePage,
        useBarcodeValueAsName: this.useBarcodeValueAsName,
        barcodeIsOnLastPage: this.barcodeOnLastPage,
        barcodeIndexID: this.barcodeIndex.value,
        prefix: this.prefix,
        splitterOption: this.selectedSplitterOption.name,
        splitterValues: this.splitterValues.map(value => value.message),
        valueFrom: this.barcodeRangeFrom,
        valueTo: this.barcodeRangeTo,
      }

      return payload
    },
    pushValue() {
      if (!this.splitterValuesDisabled) {
        this.splitterValues.push({})
      }
    },
    removeValue(index) {
      if (!this.splitterValuesDisabled) {
          this.splitterValues.splice(index, 1);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
  .mainDiv {
    width: 800px;
    height: 760px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .row{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .row-checkbox {
    margin-left: -1px;
    width: 396px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .fullWidth {
    width: 100%;
  }

  .halfWidth {
    margin-right: 10px;
    width: 50%;
  }
  .column{
    display: flex;
    flex-direction: column;
  }
  .splitter-values {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .plus-button, .minus-button {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    background-color: $grey-50;
    align-self: start;
  }

  .plus-button {
    position: relative;
    top: 20px;
  }

  .plus-and-minus-disabled {
    background-color: $grey-75;
    fill: $grey-300;
  }

  .plus-button:hover, .minus-button:hover{
    cursor: pointer;
  }
  .plus-and-minus-disabled:hover {
    cursor: default;
  }
</style>
