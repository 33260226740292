<template>
  <VasionFolderSelect
    title="Move Documents"
    okButtonText="Move"
    @cancelButtonClick="cancelClick"
    @okButtonClick="moveClick"
  />
</template>

<script>
import VasionFolderSelect from '@/components/shared/VasionFolderSelect.vue'

export default {
  name: 'DocumentMove',
  components: {
    VasionFolderSelect,
  },
  props: {
    documentIDString: {
      type: String,
      required: true,
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close')
    },
    async moveClick(payload) {
      const movePayload = {
        DocumentIdString: this.documentIDString,
        FolderId: payload.SelectedFolderID,
      }

      const moveResult = await this.$store.dispatch('document/moveDocuments', movePayload)
      this.$emit('move-complete', moveResult)
    },
  },
}
</script>
