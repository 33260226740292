<template>
  <main>
    <section v-if="currentModalView === 'main'" class="modal-bounds">
      <header class="modal-title">
        Merge Documents
      </header>
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div id="thumbnail-container" :key="thumbNailKey" class="thumbnail-container">
        <div
          v-for="(image, index) in checkedDocuments"
          :key="index"
          class="merge-thumbnail"
          :class="{ selectedThumb: imageSelected === index }"
          @click="selectNewThumb(index)"
        >
          <ThumbnailImage
            ref="mainImage"
            class="min-height"
            :documentID="image.id"
            :pageNumber="1"
            :width="120"
            :index="image.id"
            :imageString="image.tempFile"
          />
          <div class="doc-name" :class="{ docNameSelected: imageSelected === index }">
            {{ image.name }}
          </div>
          <div v-if="imageSelected === index" class="selected-container">
            <VasionButton
              id="save-button"
              class="merge-move-buttons merge-move-back"
              name="save-button"
              icon="VasionArrowLeftIcon"
              :classProp="'secondary-grey'"
              @vasionButtonClicked.stop="moveDocument('left', index)"
            />
            <VasionInput
              id="move-to-page"
              v-model="moveTo"
              name="move-to-page"
              type="text"
              inputmode="numeric"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
              inputType="blank-white"
              class="merge-move-input"
              width="36"
              :placeholder="index + 1"
              :max="checkedDocuments.length"
              @input="moveDocument(null, index, moveTo)"
            />
            <VasionButton
              id="save-button"
              class="merge-move-buttons merge-move-forward"
              name="save-button"
              icon="VasionArrowRightIcon"
              :classProp="'secondary-grey'"
              @vasionButtonClicked.stop="moveDocument('right', index)"
            />
          </div>
          <div class="number-only">
            {{ index + 1 }}
          </div>
        </div>
      </div>
      <div class="merge-row">
        <VasionCheckbox
          id="append-to-first-doc"
          name="append-to-first-doc"
          :checked="appendToFirstDocument"
          :isDisabled="useFieldsFromFistDocument"
          @change="checkBoxToggle('appendToFirstDocument', 'setMergeAppendToFirstDocument', !appendToFirstDocument)"
        >
          Append to First Document
        </VasionCheckbox>
        <div class="vasion-row">
          <VasionButton
            id="add-document"
            class="merge-buttons"
            name="add-document"
            icon="VasionAddIcon"
            :classProp="'secondary-grey'"
            @vasionButtonClicked="currentModalView = 'add'"
          />
          <VasionButton
            id="save-button"
            class="merge-buttons"
            name="save-button"
            icon="VasionRemoveIcon"
            :classProp="'secondary-grey'"
            @vasionButtonClicked="removeDocument"
          />
        </div>
      </div>
      <div class="merge-row">
        <VasionInput
          id="send-pages-text-box"
          v-model="mergeFileName"
          title="FILE NAME"
          name="send-pages-text-box"
          placeholder=""
          inputType="top-white"
          width="432"
          :isDisabled="appendToFirstDocument"
        />
        <div class="browse-group">
          <VasionInput
            id="send-pages-text-box"
            v-model="mergeFolderLocationName"
            title="FILE LOCATION"
            name="send-pages-text-box"
            placeholder=""
            inputType="top-white"
            width="374"
            :readonly="true"
            :isDisabled="appendToFirstDocument"
          />
          <md-menu
            md-align-trigger
            md-size="huge"
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            md-dense
          >
            <VasionButton
              id="select-file"
              class="button-margin-fix"
              :classProp="'primary'"
              :isDisabled="appendToFirstDocument"
              @vasionButtonClicked="currentModalView = 'browse-folders'"
            >
              Select
            </VasionButton>
          </md-menu>
        </div>
      </div>
      <div class="merge-row">
        <VasionDropList
          v-slot="slotItem"
          v-model="mergeAsValue"
          class="merge-as"
          title="MERGE AS"
          :dataArray="mergeAs"
          :showSearchField="false"
          :returnValueOnly="true"
          width="432"
          type="plain-list"
          displayName="name"
          valueName="value"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <VasionCheckbox
        id="append-to-first-doc"
        class="merge-checkbox"
        name="append-to-first-doc"
        :checked="useFieldsFromFistDocument"
        :isDisabled="appendToFirstDocument"
        @change="checkBoxToggle('useFieldsFromFistDocument', 'setmergeUseFieldsFromFistDocument', !useFieldsFromFistDocument)"
      >
        Use Fields from First Document Selected
      </VasionCheckbox>
      <VasionCheckbox
        id="append-to-first-doc"
        class="merge-checkbox"
        name="append-to-first-doc"
        :checked="deleteOriginalFiles"
        @change="checkBoxToggle('deleteOriginalFiles', 'setMergeDeleteOriginalFiles', !deleteOriginalFiles)"
      >
        Delete Original Files
      </VasionCheckbox>
      <VasionCheckbox
        id="append-to-first-doc"
        class="merge-checkbox"
        name="append-to-first-doc"
        :checked="mergeFileNotes"
        @change="checkBoxToggle('mergeFileNotes', 'setMergeMergeFileNotes', !mergeFileNotes)"
      >
        Merge File Notes
      </VasionCheckbox>
    </section>
    <section v-if="currentModalView === 'browse-folders'">
      <div class="folderDialogBrowseDiv">
        <VasionFolders
          @itemSelected="handleDialogSelection"
        />
      </div>
    </section>
    <section v-if="currentModalView === 'add'">
      <div>
        <StartWorkflowFileUpload
          :isWorkflow="false"
          :fileSelectionMode="true"
          :showBackButton="false"
          @cancelButtonClick="currentModalView = 'main'"
          @fileAdded="currentModalView = 'main'"
        />
      </div>
    </section>
    <footer v-if="currentModalView === 'main'" class="alignRight">
      <VasionButton
        id="btnFolderBrowserCancel"
        classProp="secondary"
        :isRaised="false"
        @vasionButtonClicked="$emit('cancel')"
      >
        Cancel
      </VasionButton>
      <VasionButton
        id="btnFolderBrowserOK"
        classProp="primary"
        :isRaised="false"
        @vasionButtonClicked="mergeFiles"
      >
        Merge
      </VasionButton>
    </footer>
    <footer v-if="currentModalView === 'browse-folders'" class="alignRight">
      <VasionButton
        id="btnFolderBrowserCancel"
        classProp="secondary"
        :isRaised="false"
        @vasionButtonClicked="handleBrowseDialogCancel"
      >
        Cancel
      </VasionButton>
      <VasionButton
        id="btnFolderBrowserOK"
        classProp="primary"
        :isRaised="false"
        :isDisabled="!browseDialogSelectionMade"
        @vasionButtonClicked="currentModalView = 'main'"
      >
        OK
      </VasionButton>
    </footer>
    <!-- <footer class='alignRight' v-if="currentModalView === 'add'">
      <VasionButton id="btnFolderBrowserCancel" classProp="secondary" :isRaised="false" @vasionButtonClicked="currentModalView = 'main'">Cancel</VasionButton>
      <VasionButton id="btnFolderBrowserOK" classProp="primary" :isRaised="false" @vasionButtonClicked="currentModalView = 'main'">OK</VasionButton>
    </footer> -->
  </main>
</template>

<script>
import { debounce } from 'vue-debounce'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import ThumbnailImage from '@/components/document/ThumbnailImage.vue';
import StartWorkflowFileUpload from '@/components/workflow/StartWorkflowFileUpload.vue';

export default {
  name: 'MergeFiles',
  components: {
    Loading,
    StartWorkflowFileUpload,
    ThumbnailImage,
  },
  props: {
  },
  data: function () {
    return {
      appendToFirstDocument: this.$store.state.document.mergeAppendToFirstDocument !== '' ? this.$store.state.document.mergeAppendToFirstDocument : false,
      browseDialogSelectionMade: false,
      currentModalView: 'main',
      deleteOriginalFiles: this.$store.state.document.mergeDeleteOriginalFiles !== '' ? this.$store.state.document.mergeDeleteOriginalFiles : false,
      imageSelected: 0,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      mergeAddSelectionMade: false,
      mergeAs: [
        {
          name: 'PDF',
          value: true,
        },
        {
          name: 'tiff',
          value: false,
        },
      ],
      mergeAsValue: this.$store.state.document.mergeFileAs !== '' ? this.$store.state.document.mergeFileAs : true,
      mergeFileNotes: this.$store.state.document.mergeMergeFileNotes !== '' ? this.$store.state.document.mergeMergeFileNotes : false,
      moveTo: '',
      sendPages: '',
      thumbNailKey: 0,
      useFieldsFromFistDocument: this.$store.state.document.mergeUseFieldsFromFistDocument !== '' ? this.$store.state.document.mergeUseFieldsFromFistDocument : false,
    }
  },
  computed: {
    checkedDocuments() { return this.$store.state.document.checkedDocuments },
    mergeFileName: {
      get() { return this.$store.state.document.mergeFileName },
      set(newVal) {
        this.$store.dispatch('document/setMergeFileName', newVal)
      },
    },
    mergeFolderLocationId() { return this.$store.state.document.mergeFolderLocationId },
    mergeFolderLocationName() { return this.$store.state.document.mergeFolderLocationName },
    mergeKeyForMerge() { return this.$store.state.document.mergeKeyForMerge },
    showAddDialog() { return this.currentModalView === 'add' },
    showBrowseDialog() { return this.currentModalView === 'browse-folders' },
  },
  watch: {
    mergeKeyForMerge() {
      this.mergeFiles()
    },
  },
  created() {
  },
  methods: {
    addDocument() {
    },
    checkBoxToggle(dataName, box, value) {
      this[dataName] = value
      this.$store.dispatch(`document/${box}`, value)
    },
    handleAddDocument() {
      this.$store.dispatch('document/addCheckedDocument', this.mergeAddSelection)
      this.mergeAddSelection = {}
      this.mergeAddSelectionMade = false
      this.toggleMergeAdd()
    },
    handleAddDocumentSelection(selectedData) {
      if (selectedData.isDocument) {
        const payload = { name: selectedData.name, id: selectedData.value }
        this.mergeAddSelection = payload
        this.mergeAddSelectionMade = true
      }
    },
    handleBrowseDialogCancel() {
      this.$store.dispatch('document/setMergeFolderLocation', { name: '', value: '' })
      this.browseDialogSelectionMade = false
      this.currentModalView = 'main'
    },
    handleDialogSelection(selectedData) {
      this.$store.dispatch('document/setMergeFolderLocation', selectedData)
      this.browseDialogSelectionMade = true
    },
    moveDocument(direction, index, toIndex) {
      const dFn = debounce((payload) => {
        this.$store.dispatch('document/moveMergeDocuments', payload)
        this.imageSelected = payload.to
        this.moveTo = ''
        if (this.checkedDocuments.length > 5) {
            const calculatedScrollTo = (100 * payload.to)
            document.getElementById('thumbnail-container').scrollLeft = calculatedScrollTo;
          }
      }, '1500ms')
      const payload = {
        from: index,
        to: null,
      }
      if (toIndex) {
        if (toIndex > this.checkedDocuments.length) {
          payload.to = this.checkedDocuments.length - 1
        } else if (payload.to === 0) {
          payload.to = 1
        } else {
          payload.to = parseInt(toIndex, 10) - 1
        }
      } else if (direction === 'right') {
          this.imageSelected += 1
          payload.to = index + 1
      } else {
        this.imageSelected -= 1
          payload.to = index - 1
      }
      if (toIndex) {
        dFn(payload)
      } else {
        this.$store.dispatch('document/moveMergeDocuments', payload)
      }
    },
    async mergeFiles() {
      this.isLoading = true
      const newFiles = this.checkedDocuments.filter(item => typeof item.id === 'string').map(item => item.id)
      const filteredValues = await this.$store.dispatch('document/mergeDocumentsFilter', { Values: this.checkedDocuments.map(item => item.id) })
      if (filteredValues.Value) {
        this.isLoading = false
          const messageObject = {
            title: 'Files Did Not Merge!',
            subTitle:  filteredValues.Value,
            modalToClose: 'showMergeDialog',
            value: false,
          }
          this.$emit('showSnackbar', messageObject)
          this.$store.dispatch('document/setMergeClearValues')
      }
      const mergeFolderLocationId = this.mergeFolderLocationId ? this.mergeFolderLocationId : 0
      const payload = {
        FileName: this.mergeFileName,
        FolderId: mergeFolderLocationId,
        AppendToFirstDoc: this.appendToFirstDocument,
        MergeAsPDF: this.mergeAsValue === true || this.mergeAsValue === 'PDF',
        UseFirstDocIndexData: this.useFieldsFromFistDocument,
        DeleteOriginals: this.deleteOriginalFiles,
        MergeDocNotes: this.mergeFileNotes,
      }
      if (newFiles.length) {
        // eslint-disable-next-line dot-notation
        payload['DocumentIdsAndTempFiles'] = filteredValues.Values
      } else {
        // eslint-disable-next-line dot-notation
        payload['DocumentIds'] = filteredValues.Values.map(item => parseInt(item, 10))
      }
      try {
        const res = await this.$store.dispatch('document/mergeDocuments', payload)
        this.isLoading = false
        if (res.Value === '') {
          const messageObject = {
            title: 'Files Merged!',
            subTitle: 'Check your destination folder.',
            modalToClose: 'showMergeDialog',
            value: true,
          }
          this.$emit('showSnackbar', messageObject)
          this.$store.dispatch('document/setMergeClearValues')
        } else {
          this.isLoading = false
          const messageObject = {
            title: 'Files Did Not Merge!',
            subTitle: res.Value,
            modalToClose: 'showMergeDialog',
            value: false,
          }
          this.$emit('showSnackbar', messageObject)
          this.$store.dispatch('document/setMergeClearValues')
        }
      } catch {
        this.isLoading = false
        const messageObject = {
          title: 'Files Did Not Merge!',
          subTitle: 'Check your with your administrator.',
          modalToClose: 'showMergeDialog',
          value: false,
        }
        this.$emit('showSnackbar', messageObject)
        this.$store.dispatch('document/setMergeClearValues')
      }
    },
    removeDocument() {
      this.$store.dispatch('document/removeMergeDocument', this.imageSelected)
    },
    selectNewThumb(index) {
      this.imageSelected = index
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';
  .alignRight {
    text-align: right;
  }

  .browse-group {
    display: flex;
    flex-direction: row;
  }
  .button-margin-fix {
    position: relative;
    top: 17px;
  }
  .min-height {
    min-height: 120px;
  }
  .modal-bounds {
    width: 928px;
    background-color: $white;
  }
  .modal-title {
    @include SubtitleActive;
    font-size: 1.75em;
  }
  .number-only {
    position: absolute;
    top: 174px;
  }
  .folderDialogBrowseDiv {
    width: 400px;
    height: 300px;
    overflow: auto;
  }
  .thumbnail-container {
    width: 100%;
    height: 221px;
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    padding-left: 8px;
    border: solid 1px $grey-100;
    overflow-y: auto;
  }
  .merge-as {
    margin-bottom: 32px;
  }
  .merge-buttons:not(:last-child)  {
    margin-right: 8px;
  }
  .merge-move-buttons {
    position: absolute;
    top: 165px;
  }
  .merge-move-back {
    right: 99px;
  }
  .merge-move-forward {
    right: 9px;
  }
  .merge-move-input {
    position: absolute;
    top: 164px;
    right: 56px;
  }
  .merge-checkbox:not(:last-child) {
    margin-bottom: 24px
  }

  .merge-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 21px;
    width: 100%;
  }
  .merge-thumbnail {
    margin-left: 8px;
    min-width: 149px;
    height: 156px;
    padding-top: 11px;
    border-radius: 2.9px;
    border: solid 1px $grey-100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $grey-50;
    position: relative;
    bottom: 25px;
    cursor: pointer;
  }
  .selectedThumb {
    border: 1px solid $secondary;
  }
  .doc-name {
    font-family: $font-family-medium;
    height: 32px;
    width: 100%;
    background-color: white;
    padding-left: 8px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 2px;
    overflow: auto;
  }
  .docNameSelected {
    border-top: 1px solid $secondary;
  }
</style>
